
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import cloneDeep from 'lodash/cloneDeep';
import { createPopper } from '@popperjs/core';

@Component({
  components: { VueSelect },
})
export default class TagMultiSelect extends Vue {
  @Prop({ default: false }) useSwitches!: boolean | string;

  @Prop({ default: () => [] }) value!: any[];

  @Prop({ default: () => [] }) options!: { value: string; label: string }[];

  @Prop({ default: 3 }) maxFulltextLabels!: number;

  @Prop({ default: 'items' }) itemsCountLabel!: string;

  /**
   * Loading state label start
   *
   * Label presented in selector if `loading` is true.
   * This label will prepend `itemsCountLabel`.
   *
   * Default set to 'Loading'
   */
  @Prop({ default: 'Loading' }) loadingPrependLabel!: string;

  /**
   * String to append to default placeholder
   *
   * Defaults to empty string
   */
  @Prop({ default: '' }) appendLabelToPlaceholder!: string;

  /**
   * Current loading state
   *
   * if set, selector will be in a loading state until `loading` is false
   */
  @Prop({ default: false }) loading!: string | boolean;

  @Prop({ default: false }) readonly!: boolean | string;

  @Prop({ default: false }) appendToBody!: boolean | string;

  /**
   * Placeholder text
   *
   * If unset, will default to `All {items label}`
   * FIXME this default value presumes this component is only used as a filter, presumption should be removed
   */
  @Prop({ default: '' }) placeholder!: string;

  private active = false;

  getOptionKey(option: any) {
    return option.value || option;
  }

  clearSearchOnBlur() {
    return true;
  }

  onOptionSelected(option: any) {
    const index = this.optionIndex(option);
    const value = [...this.value];
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(cloneDeep(option.value));
    }
    this.$emit('update:value', value);
  }

  isOptionSelected(option: any) {
    return this.optionIndex(option) > -1;
  }

  optionIndex(option: any) {
    return this.value.findIndex((i) => i === option.value);
  }

  isLastOption(option: any) {
    return this.optionIndex(option) === this.value.length - 1;
  }

  get isLoading() {
    return this.loading !== false;
  }

  get isReadonly() {
    return this.readonly !== false;
  }

  get searchText() {
    return `Search for ${this.itemsCountLabel}...`;
  }

  get defaultText() {
    return `All ${this.appendLabelToPlaceholder}`;
  }

  get loadingText() {
    return `${this.loadingPrependLabel} ${this.itemsCountLabel}...`;
  }

  get placeholderText() {
    return this.placeholder || this.defaultText;
  }

  get itemsText() {
    if (this.isLoading) {
      return this.loadingText;
    }
    if (this.active) {
      return this.searchText;
    }
    if (this.value.length === 0) {
      return this.placeholderText;
    }
    if (this.value.length <= this.maxFulltextLabels) {
      return this.value
        .map((i) => {
          const option = this.options.find((o) => o.value === i);
          return option ? option.label : i;
        })
        .join(', ');
    }
    return `${this.value.length} ${this.itemsCountLabel}`;
  }

  get placeholderClass() {
    return [this.searchText, this.placeholderText, this.loadingText].includes(this.itemsText) ? '' : 'opacity-full';
  }

  withPopper(dropdownList: HTMLElement, component: Vue) {
    /**
     * We need to explicitly define the dropdown width since
     * it is usually inherited from the parent with CSS.
     */
    dropdownList.style.width = `${(component.$el as HTMLElement).getBoundingClientRect().width}px`;
    const popper = createPopper(component.$refs.toggle as any, dropdownList, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'positioningClass',
          enabled: true,
          phase: 'write',
          fn({ state }) {
            component.$el.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-down', state.placement !== 'top');
          },
        },
      ],
    });

    return () => popper.destroy();
  }
}
