import type { AppConfig } from 'bd-common/src/config';
import App from '@/App.vue';
import { routes } from './app/routes';
import { partnerRoles } from 'ah-api-gateways';

const baseConfig = require(process.env.VUE_APP_CONFIG_FILE!).default;

export const config: AppConfig = {
  allowedRoles: partnerRoles,
  isProduction: process.env.NODE_ENV === 'production',
  showDevTools: false,
  routerMode: 'history',
  staticOTP: true,
  rootComponent: App,
  routes,
  ...baseConfig,
  // FIXME add an alt referer per environment
  altReferer: 'https://dev.adminportal.alt21.com/',
};
